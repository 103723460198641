@responsive {
  .tiles_containerSpacingSm {
    @apply -ml-4 -mt-4;
  }
  .tiles_containerSpacingMd {
    @apply -ml-8 -mt-8;
  }
  .tiles_containerSpacingLg {
    @apply -ml-12 -mt-12;
  }
  .tiles_containerSpacingXl {
    @apply -ml-20 -mt-20;
  }

  .tiles_alignLeft {
    @apply .justify-start;
  }
  .tiles_alignCenter {
    @apply .justify-center;
  }
  .tiles_alignRight {
    @apply .justify-end;
  }
  .tiles_alignJustify {
    @apply .justify-between;
  }

  .tiles_tileSpacingSm {
    @apply pl-4 pt-4;
  }
  .tiles_tileSpacingMd {
    @apply pl-8 pt-8;
  }
  .tiles_tileSpacingLg {
    @apply pl-12 pt-12;
  }
  .tiles_tileSpacingXl {
    @apply pl-20 pt-20;
  }

  .tiles_tileWidthAuto {
    @apply w-auto;
  }
  .tiles_tileWidth1 {
    @apply w-full;
  }
  .tiles_tileWidth2 {
    @apply w-1/2;
  }
  .tiles_tileWidth3 {
    @apply w-1/3;
  }
  .tiles_tileWidth4 {
    @apply w-1/4;
  }
  .tiles_tileWidth5 {
    @apply w-1/5;
  }
}
