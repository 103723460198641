.Tiles_tiles_containerSpacingSm__1-dvf {
  margin-left: -1rem;
  margin-top: -1rem;
}

.Tiles_tiles_containerSpacingMd__2N1Sd {
  margin-left: -2rem;
  margin-top: -2rem;
}

.Tiles_tiles_containerSpacingLg__3Hky5 {
  margin-left: -3rem;
  margin-top: -3rem;
}

.Tiles_tiles_containerSpacingXl__3F3M8 {
  margin-left: -5rem;
  margin-top: -5rem;
}

.Tiles_tiles_alignLeft__1KrAV {
  justify-content: flex-start;
}

.Tiles_tiles_alignCenter__2bjCt {
  justify-content: center;
}

.Tiles_tiles_alignRight__1cJ-h {
  justify-content: flex-end;
}

.Tiles_tiles_alignJustify__2w4f_ {
  justify-content: space-between;
}

.Tiles_tiles_tileSpacingSm__2Z2x8 {
  padding-left: 1rem;
  padding-top: 1rem;
}

.Tiles_tiles_tileSpacingMd__2WNab {
  padding-left: 2rem;
  padding-top: 2rem;
}

.Tiles_tiles_tileSpacingLg__3iJyP {
  padding-left: 3rem;
  padding-top: 3rem;
}

.Tiles_tiles_tileSpacingXl__2LshU {
  padding-left: 5rem;
  padding-top: 5rem;
}

.Tiles_tiles_tileWidthAuto__2AZyc {
  width: auto;
}

.Tiles_tiles_tileWidth1__wAM3N {
  width: 100%;
}

.Tiles_tiles_tileWidth2__1_ztJ {
  width: 50%;
}

.Tiles_tiles_tileWidth3__21wmM {
  width: 33.333333%;
}

.Tiles_tiles_tileWidth4__14dfV {
  width: 25%;
}

.Tiles_tiles_tileWidth5__UbAwQ {
  width: 20%;
}

@media (min-width: 640px) {
  .Tiles_sm\:tiles_containerSpacingSm__26iqB {
    margin-left: -1rem;
    margin-top: -1rem;
  }

  .Tiles_sm\:tiles_containerSpacingMd__20P2g {
    margin-left: -2rem;
    margin-top: -2rem;
  }

  .Tiles_sm\:tiles_containerSpacingLg__3X362 {
    margin-left: -3rem;
    margin-top: -3rem;
  }

  .Tiles_sm\:tiles_containerSpacingXl___Oq88 {
    margin-left: -5rem;
    margin-top: -5rem;
  }

  .Tiles_sm\:tiles_alignLeft__26hrq {
    justify-content: flex-start;
  }

  .Tiles_sm\:tiles_alignCenter__5Yl2h {
    justify-content: center;
  }

  .Tiles_sm\:tiles_alignRight__3YHCQ {
    justify-content: flex-end;
  }

  .Tiles_sm\:tiles_alignJustify__28Dgy {
    justify-content: space-between;
  }

  .Tiles_sm\:tiles_tileSpacingSm__7_ugN {
    padding-left: 1rem;
    padding-top: 1rem;
  }

  .Tiles_sm\:tiles_tileSpacingMd__20Kmr {
    padding-left: 2rem;
    padding-top: 2rem;
  }

  .Tiles_sm\:tiles_tileSpacingLg__xsWg3 {
    padding-left: 3rem;
    padding-top: 3rem;
  }

  .Tiles_sm\:tiles_tileSpacingXl__MaixI {
    padding-left: 5rem;
    padding-top: 5rem;
  }

  .Tiles_sm\:tiles_tileWidthAuto__1CR2Q {
    width: auto;
  }

  .Tiles_sm\:tiles_tileWidth1__44VTY {
    width: 100%;
  }

  .Tiles_sm\:tiles_tileWidth2__1kOER {
    width: 50%;
  }

  .Tiles_sm\:tiles_tileWidth3__3ZShw {
    width: 33.333333%;
  }

  .Tiles_sm\:tiles_tileWidth4__1wMPm {
    width: 25%;
  }

  .Tiles_sm\:tiles_tileWidth5__3o24l {
    width: 20%;
  }
}

@media (min-width: 768px) {
  .Tiles_md\:tiles_containerSpacingSm__3aQnl {
    margin-left: -1rem;
    margin-top: -1rem;
  }

  .Tiles_md\:tiles_containerSpacingMd__3ZGXf {
    margin-left: -2rem;
    margin-top: -2rem;
  }

  .Tiles_md\:tiles_containerSpacingLg__p-2xe {
    margin-left: -3rem;
    margin-top: -3rem;
  }

  .Tiles_md\:tiles_containerSpacingXl__2gR8I {
    margin-left: -5rem;
    margin-top: -5rem;
  }

  .Tiles_md\:tiles_alignLeft__3yJpJ {
    justify-content: flex-start;
  }

  .Tiles_md\:tiles_alignCenter__3CjPL {
    justify-content: center;
  }

  .Tiles_md\:tiles_alignRight__2gPXP {
    justify-content: flex-end;
  }

  .Tiles_md\:tiles_alignJustify__2Nr_- {
    justify-content: space-between;
  }

  .Tiles_md\:tiles_tileSpacingSm__38uYM {
    padding-left: 1rem;
    padding-top: 1rem;
  }

  .Tiles_md\:tiles_tileSpacingMd__1pahM {
    padding-left: 2rem;
    padding-top: 2rem;
  }

  .Tiles_md\:tiles_tileSpacingLg__2IPIX {
    padding-left: 3rem;
    padding-top: 3rem;
  }

  .Tiles_md\:tiles_tileSpacingXl__1yzOO {
    padding-left: 5rem;
    padding-top: 5rem;
  }

  .Tiles_md\:tiles_tileWidthAuto__3-fMA {
    width: auto;
  }

  .Tiles_md\:tiles_tileWidth1__20XE4 {
    width: 100%;
  }

  .Tiles_md\:tiles_tileWidth2__2e61j {
    width: 50%;
  }

  .Tiles_md\:tiles_tileWidth3__1jjcg {
    width: 33.333333%;
  }

  .Tiles_md\:tiles_tileWidth4__239Y4 {
    width: 25%;
  }

  .Tiles_md\:tiles_tileWidth5__1-arQ {
    width: 20%;
  }
}

@media (min-width: 1024px) {
  .Tiles_lg\:tiles_containerSpacingSm__3lUaH {
    margin-left: -1rem;
    margin-top: -1rem;
  }

  .Tiles_lg\:tiles_containerSpacingMd__1GxGh {
    margin-left: -2rem;
    margin-top: -2rem;
  }

  .Tiles_lg\:tiles_containerSpacingLg__1VqwL {
    margin-left: -3rem;
    margin-top: -3rem;
  }

  .Tiles_lg\:tiles_containerSpacingXl__hAXHz {
    margin-left: -5rem;
    margin-top: -5rem;
  }

  .Tiles_lg\:tiles_alignLeft__sWZOc {
    justify-content: flex-start;
  }

  .Tiles_lg\:tiles_alignCenter__1gtiv {
    justify-content: center;
  }

  .Tiles_lg\:tiles_alignRight__3tN_z {
    justify-content: flex-end;
  }

  .Tiles_lg\:tiles_alignJustify__3OoZI {
    justify-content: space-between;
  }

  .Tiles_lg\:tiles_tileSpacingSm__3UzpL {
    padding-left: 1rem;
    padding-top: 1rem;
  }

  .Tiles_lg\:tiles_tileSpacingMd__1aFnX {
    padding-left: 2rem;
    padding-top: 2rem;
  }

  .Tiles_lg\:tiles_tileSpacingLg__3LZdW {
    padding-left: 3rem;
    padding-top: 3rem;
  }

  .Tiles_lg\:tiles_tileSpacingXl__K_EnE {
    padding-left: 5rem;
    padding-top: 5rem;
  }

  .Tiles_lg\:tiles_tileWidthAuto__3hi3b {
    width: auto;
  }

  .Tiles_lg\:tiles_tileWidth1__2Jjmu {
    width: 100%;
  }

  .Tiles_lg\:tiles_tileWidth2__1FjLn {
    width: 50%;
  }

  .Tiles_lg\:tiles_tileWidth3__2zzrk {
    width: 33.333333%;
  }

  .Tiles_lg\:tiles_tileWidth4__1RjyO {
    width: 25%;
  }

  .Tiles_lg\:tiles_tileWidth5__2MwkT {
    width: 20%;
  }
}

@media (min-width: 1280px) {
  .Tiles_xl\:tiles_containerSpacingSm__EelaA {
    margin-left: -1rem;
    margin-top: -1rem;
  }

  .Tiles_xl\:tiles_containerSpacingMd__3_VAn {
    margin-left: -2rem;
    margin-top: -2rem;
  }

  .Tiles_xl\:tiles_containerSpacingLg__3FOPB {
    margin-left: -3rem;
    margin-top: -3rem;
  }

  .Tiles_xl\:tiles_containerSpacingXl__gprZv {
    margin-left: -5rem;
    margin-top: -5rem;
  }

  .Tiles_xl\:tiles_alignLeft__t-0YD {
    justify-content: flex-start;
  }

  .Tiles_xl\:tiles_alignCenter__21jwP {
    justify-content: center;
  }

  .Tiles_xl\:tiles_alignRight__jnCCu {
    justify-content: flex-end;
  }

  .Tiles_xl\:tiles_alignJustify__mI3Cu {
    justify-content: space-between;
  }

  .Tiles_xl\:tiles_tileSpacingSm__1uwrh {
    padding-left: 1rem;
    padding-top: 1rem;
  }

  .Tiles_xl\:tiles_tileSpacingMd__sfOcJ {
    padding-left: 2rem;
    padding-top: 2rem;
  }

  .Tiles_xl\:tiles_tileSpacingLg__2eCzU {
    padding-left: 3rem;
    padding-top: 3rem;
  }

  .Tiles_xl\:tiles_tileSpacingXl__3ep3A {
    padding-left: 5rem;
    padding-top: 5rem;
  }

  .Tiles_xl\:tiles_tileWidthAuto__3FaIm {
    width: auto;
  }

  .Tiles_xl\:tiles_tileWidth1__2LlZs {
    width: 100%;
  }

  .Tiles_xl\:tiles_tileWidth2__mePoa {
    width: 50%;
  }

  .Tiles_xl\:tiles_tileWidth3__2_52r {
    width: 33.333333%;
  }

  .Tiles_xl\:tiles_tileWidth4__2AFKZ {
    width: 25%;
  }

  .Tiles_xl\:tiles_tileWidth5__Sh-R5 {
    width: 20%;
  }
}

